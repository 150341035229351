import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import type { TagProps } from './Tag';

export const useCustomStyles = makeStyles<Pick<TagProps, 'size' | 'color' | 'variant' | 'rounded'>>()(
	(theme, { size = 'medium', color = 'orange', variant = 'filled', rounded }) => ({
		container: {
			display: 'flex',
			width: 'fit-content',
			minWidth: 'fit-content',
			alignItems: 'center',
			boxSizing: 'border-box',
			height: theme.tag.size[size],
			gap: size === 'medium' ? theme.spacing.tiny : theme.spacing.micro,
			padding: size === 'medium' ? theme.spacing.xxsmall : `${theme.spacing.tiny} ${theme.spacing.xxsmall}`,
			font: theme.tag[size === 'medium' ? 'default' : size],
			whiteSpace: 'nowrap',
			textTransform: 'uppercase',
			// we need these ignore to suppress error caused by whiteAlt color that has no outline variant
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore-next-line
			color: theme.tag[color][variant]?.foreground.color,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore-next-line
			border: variant === 'outline' ? theme.tag.border.outline[color] : undefined,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore-next-line
			borderColor: variant === 'outline' ? theme.tag[color]?.outline.border.color : undefined,
			borderRadius: theme.tag[rounded ? 'pill' : 'rounded'],
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore-next-line
			backgroundColor: theme.tag[color][variant]?.background.color,
		},
	}),
);
