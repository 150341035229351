import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	partnerItem: {
		listStyleType: 'none',
	},
	partnerItemLink: {
		cursor: 'pointer',
		display: 'flex',
		flexShrink: 0,
		height: theme.spacing.medium,
		position: 'relative',
	},
	image: {
		objectFit: 'contain',
	},
}));
