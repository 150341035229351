import { assertNever } from '@vakantiesnl/services/src/util/assertNever';

import { TypographyType } from './Typography';
import { makeStyles } from '../../styles/themes/themewrapper';

export const useCustomStyles = makeStyles<TypographyType>()((theme, variant) => {
	const font = theme.typography[variant];
	let color;

	switch (variant) {
		case 'body2Xs':
		case 'bodyXs':
		case 'bodySm':
		case 'bodyMd':
		case 'bodyLg':
			color = theme.body.foreground.color.default;
			break;

		case 'labelXs':
		case 'labelSm':
		case 'labelMd':
		case 'linkSm':
		case 'linkMd':
		case 'linkLg':
			color = undefined;
			break;

		case 'displayLg':
		case 'displayXl':
		case 'heading2Xs':
		case 'headingXs':
		case 'headingSm':
		case 'headingMd':
		case 'headingLg':
		case 'headingXl':
		case 'heading2Xl':
			color = theme.heading.color;
			break;

		default:
			assertNever(variant);
	}

	return {
		root: {
			font,
			color,
			margin: 0,
			whiteSpace: 'pre-wrap',
		},
	};
});
