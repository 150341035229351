import { FC, memo } from 'react';

import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';

import { useStyles } from './PartnerItem.style';

export type PartnerItemProps = {
	title: string;
	href: string;
	imgSrc: string;
	imgHoverSrc: string;
	priority?: boolean;
};

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

const PartnerItem: FC<PartnerItemProps> = ({ href, imgHoverSrc, title, priority = false }) => {
	const { classes } = useStyles();

	return (
		<li className={classes.partnerItem}>
			<a className={classes.partnerItemLink} href={href} target="_blank" rel="noreferrer">
				<ImageComponent
					contentfulParams={contentfulParams}
					className={classes.image}
					priority={priority}
					alt={title}
					height={22}
					width={35}
					src={imgHoverSrc}
				/>
			</a>
		</li>
	);
};

const memoizedPartnerItem = memo(PartnerItem);

export { memoizedPartnerItem as PartnerItem };
