import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<{ primaryColor: boolean }, 'price' | 'prefix'>()(
	(theme, { primaryColor }, classes) => ({
		price: {
			fontFamily: theme.fontFamily.primary,
			fontWeight: theme.weight.bold,
			color: primaryColor ? theme.brand.color.default : theme.palette.neutral[100],
		},
		priceWithoutDiscount: {
			position: 'relative',
			fontWeight: theme.weight.bold,
			textDecoration: 'line-through',
		},
		priceContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing.xxsmall,
			justifyContent: 'flex-start',
			[getFromBreakpoint('md')]: { justifyContent: 'flex-end' },
		},
		priceWrapper: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing.micro,
		},
		extraSmall: {
			[`& .${classes.price}`]: {
				fontSize: theme.fontSize.medium,
			},
			[`& .${classes.prefix}`]: {
				fontSize: theme.fontSize.tiny,
			},
		},
		small: {
			width: '100%',

			[`& .${classes.price}`]: {
				fontSize: theme.fontSize.large,
			},
			[`& .${classes.prefix}`]: {
				fontSize: theme.fontSize.tiny,
			},
		},
		medium: {
			width: '100%',

			[`& .${classes.price}`]: {
				fontSize: theme.fontSize.xLarge,
			},
			[`& .${classes.prefix}`]: {
				fontSize: theme.fontSize.xxSmall,
			},
		},
		large: {
			[`& .${classes.price}`]: {
				fontSize: theme.fontSize.big,
			},
			[`& .${classes.prefix}`]: {
				fontSize: theme.fontSize.xSmall,
			},
		},
		prefix: {},
		prefixTitle: {
			display: 'inline',
			color: theme.card.foreground.color.dimmed,
		},
		dealTag: {
			display: 'flex',
			alignItems: 'flex-end',
		},
	}),
);
