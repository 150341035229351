import { FC, useMemo } from 'react';

import { Tag } from '@vakantiesnl/components/src/atoms/Tag';
import { getFormattedPrice, getFormattedRoundedPrice } from '@vakantiesnl/services/src/util/mathUtils';

import { useCustomStyles } from './Price.style';
import { Typography } from '../Typography';

export type PriceProps = {
	/** The current price in cents */
	price: number;
	/** Original price to show */
	priceWithoutDiscount?: number | null;
	/** Prefix to show above the price */
	prefix?: string;
	/** Size of the price */
	size?: 'extraSmall' | 'small' | 'medium' | 'large';
	/** Custom classname */
	className?: string;
	/** Formatting of the price is rounding or into decimal */
	rounded?: boolean;
	/** Whether to show the price in the brands primary color */
	primaryColor?: boolean;
	/** Show a tag when the price has a discount */
	showDiscountTag?: boolean;
};

export const Price: FC<PriceProps> = ({
	price,
	priceWithoutDiscount,
	prefix,
	size = 'small',
	className,
	rounded = true,
	primaryColor = true,
	showDiscountTag = false,
}) => {
	const { classes, cx } = useCustomStyles({ primaryColor });
	const hasDiscount = useMemo(
		() => priceWithoutDiscount && priceWithoutDiscount > price,
		[price, priceWithoutDiscount],
	);

	return (
		<div
			className={cx(
				{
					[classes.extraSmall]: size === 'extraSmall',
					[classes.small]: size === 'small',
					[classes.medium]: size === 'medium',
					[classes.large]: size === 'large',
				},
				className,
			)}
		>
			<div className={classes.priceWrapper}>
				{prefix && (
					<div className={classes.prefix}>
						<Typography as="p" variant="labelXs" className={classes.prefixTitle}>
							{prefix}{' '}
						</Typography>
						{hasDiscount && priceWithoutDiscount && (
							<span className={classes.priceWithoutDiscount}>
								{rounded
									? getFormattedRoundedPrice(priceWithoutDiscount)
									: getFormattedPrice(priceWithoutDiscount)}
							</span>
						)}
					</div>
				)}

				<span className={classes.priceContainer}>
					{showDiscountTag && (
						<div data-cy="zooverDealTag" className={classes.dealTag}>
							<Tag color="yellow" leadingIcon="bolt" size="small" />
						</div>
					)}
					<span className={classes.price}>
						{rounded ? getFormattedRoundedPrice(price) : getFormattedPrice(price)}
					</span>
				</span>
			</div>
		</div>
	);
};
