import { FC, useRef, useEffect, useMemo } from 'react';

import { assertNever } from '@vakantiesnl/services/src/util/assertNever';
import Script from 'next/script';

import { useStyles } from './TrustPilot.style';

type Props = {
	type?: 'carousel' | 'simple' | 'micro';
};

export const TrustPilot: FC<Props> = ({ type = 'simple' }) => {
	const ref = useRef(null);
	const { cx, classes } = useStyles();

	useEffect(() => {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);

	const widgetConfig = useMemo(() => {
		switch (type) {
			case 'carousel':
				return {
					'data-style-height': '320px',
					'data-style-width': '100%',
					'data-template-id': process.env.NEXT_PUBLIC_TRUSTPILOT_CAROUSEL_WIDGET_TEMPLATE_ID,
					'data-stars': '3,4,5',
					'data-review-languages': 'nl',
				};
			case 'micro':
				return {
					'data-style-height': '24px',
					'data-style-width': '260px',
					'data-template-id': '5419b732fbfb950b10de65e5',
					'data-theme': 'dark',
				};
			case 'simple':
				return {
					'data-style-height': '20px',
					'data-style-width': '430px',
					'data-template-id': process.env.NEXT_PUBLIC_TRUSTPILOT_SIMPLE_WIDGET_TEMPLATE_ID,
				};
			default:
				assertNever(type);
		}
	}, [type]);

	const widgetClassName = useMemo(() => {
		switch (type) {
			case 'carousel':
				return classes.carouselStyles;
			case 'micro':
				return classes.microStyles;
			case 'simple':
				return classes.trustpilotStyles;
			default:
				assertNever(type);
		}
	}, [type, classes]);

	return (
		<>
			<div>
				<div
					ref={ref}
					className={cx(classes.container, 'trustpilot-widget', widgetClassName)}
					data-locale="nl-NL"
					data-businessunit-id={process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESSUNIT_ID}
					{...widgetConfig}
				/>
			</div>
			<Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" strategy="lazyOnload" />
		</>
	);
};
