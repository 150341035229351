import { FC, PropsWithChildren } from 'react';

import { Icon, IconTypes } from '@vakantiesnl/components/src/atoms/Icon';

import { useCustomStyles } from './Tag.style';

export type TagProps = {
	size?: 'medium' | 'small';
	color?: 'orange' | 'teal' | 'purple' | 'black' | 'gold' | 'silver' | 'yellow' | 'white' | 'whiteAlt' | 'red';
	variant?: 'filled' | 'outline';
	rounded?: boolean;
	leadingIcon?: IconTypes;
	tailingIcon?: IconTypes;
};

const iconSizeMap = {
	medium: 'small',
	small: 'xSmall',
} as const;

export const Tag: FC<PropsWithChildren<TagProps>> = ({
	children,
	size = 'medium',
	color = 'orange',
	variant = 'filled',
	rounded,
	tailingIcon,
	leadingIcon,
}) => {
	const { classes } = useCustomStyles({ size, color, variant, rounded });

	return (
		<div data-cy="Tag" className={classes.container}>
			{leadingIcon && <Icon type={leadingIcon} size={iconSizeMap[size]} />}
			{children}
			{tailingIcon && <Icon type={tailingIcon} size={iconSizeMap[size]} />}
		</div>
	);
};
